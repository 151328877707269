/* Estilos generales del footer */
/* Estilos generales del footer */
.footer {
    display: flex;
    justify-content: space-between; /* Espacia los bloques uniformemente */
    align-items: flex-start;
    padding: 20px;
    background: linear-gradient(to right, #02126f 0%, #004094 100%); /* Gradiente azul */
    color: white;
    flex-wrap: wrap; /* Hace que los bloques se envuelvan en pantallas pequeñas */
    width: 100%; /* Asegura que ocupe todo el ancho */
    box-sizing: border-box; /* Para incluir padding en el tamaño total */
  }
  
  /* Bloques del footer */
  .footer-block {
    flex: 1;
    margin: 10px;
    min-width: 250px; /* Tamaño mínimo para evitar que los bloques se hagan demasiado pequeños */
    text-align: left;
  }
  
  /* Estilo del logo */
  .footer-logo {
    width: 100px;
    height: auto;
    margin-bottom: 10px;
  }
  
  /* Enlaces en el footer */
  .footer-block ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-block ul li {
    margin-bottom: 10px;
  }
  
  .footer-block ul li a {
    color: white;
    text-decoration: none;
  }
  
  .footer-block ul li a:hover {
    text-decoration: underline;
  }
  
  /* Media Queries para pantallas más pequeñas */
  @media (max-width: 768px) {
    .footer {
      flex-direction: column; /* Cambia los bloques a columnas */
      align-items: center; /* Centra los bloques en pantallas pequeñas */
      text-align: center;
    }
  
    .footer-block {
      width: 100%; /* Asegura que los bloques ocupen todo el ancho */
      margin-bottom: 20px;
      text-align: center; /* Centra el contenido dentro de cada bloque */
    }
  
    .footer-block ul {
      padding-left: 0;
    }
  }