.div-image-carga {
    width: 420px;
    height: 420px;
    overflow: hidden; /* Oculta cualquier parte de la imagen que exceda los límites del contenedor */
}

.div-image-carga img {
    width: 100%; /* La imagen ocupa el 100% del ancho del contenedor */
    height: 100%; /* La imagen ocupa el 100% de la altura del contenedor */
    object-fit: cover; /* La imagen se ajusta manteniendo su relación de aspecto y cubriendo el área del contenedor */
}

