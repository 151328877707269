#digitaliza {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, #e4e4e4 0%, #f6f6f6 100%);
  padding: 40px 20px;
  min-height: 840px;
}

.container1d, .container2d {
  flex: 1 1 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.container2d {
  padding-left: 40px;
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.container2d.visible {
  opacity: 1;
  transform: translateX(0);
}

.pc {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.pc.visible {
  opacity: 1;
  transform: translateY(0);
}

.h1-d, .h2-d, .h3-d {
  color: #0455BF;
  margin: 0;
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.container2d.visible .h1-d,
.container2d.visible .h2-d,
.container2d.visible .h3-d {
  opacity: 1;
  transform: translateX(0);
}

.h1-d {
  font-size: 72px;
  font-weight: 700;
  transition-delay: 0.1s;
}

.h2-d {
  font-size: 40px;
  font-weight: 500;
  transition-delay: 0.2s;
}

.h3-d {
  font-size: 26px;
  font-weight: 400;
  transition-delay: 0.3s;
}

@media (min-width: 768px) {
  .container1d, .container2d {
      flex: 1 1 50%;
  }
}