#footer{
    width: 90%;
}
.footer-container{
    padding: 20px 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.footer-maintext{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.footer-text{
    font-size: 16px;
    font-weight: 600;
    color:#03178C;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-right: 5px;

}

.footer-para{
    font-size: 12px;
    font-weight: 300;
    color:#03178C;
    margin-block-start: 0;
    margin-block-end: 0;
}

@media screen and (max-width: 420px) {
    #footer{
        width: 70%;
    }
}