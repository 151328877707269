#aboutnew {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 840px;
  padding: 40px 20px;
  background: linear-gradient(to right, #02126f 0%, #004094 100%);
}

.container1about, .container2about {
  flex: 1 1 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.h3-about {
  color: #FFFFFF;
  font-size: 26px;
  font-weight: 400;
  line-height: 1.6;
  margin: 0;
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.h3-about.visible {
  opacity: 1;
  transform: translateX(0);
}

.h1-about {
  color: #FFFFFF;
  font-size: 48px;
  font-weight: 700;
  margin: 0 0 20px 0;
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.h1-about.visible {
  opacity: 1;
  transform: translateY(0);
}

.h2-about {
  color: #FFFFFF;
  font-size: 40px;
  font-weight: 500;
  margin: 0;
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.h2-about.visible {
  opacity: 1;
  transform: translateY(0);
}

@media (min-width: 768px) {
  .container1about, .container2about {
      flex: 1 1 50%;
  }
}