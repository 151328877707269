.div-inventaripdfgeneral {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;
    gap: 20px;
}
  
.div-inventaripdftitulo {
    background-color: white;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
  
.div_boton {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
  
.div_boton,
.button-group-container {
    display: flex;
    align-items: center;
    gap: 12px;
}
  
.icon-eliminarcliente {
    color: #03178C;
    font-size: 24px;
}
  
.pdf-button {
    text-transform: none !important;
}
  
.div-inventariopdfcontent {
    flex: 1;
    display: flex;
    flex-direction: column;
}
  
.pdf-viewer {
    width: 100%;
    height: 90vh;
    border: none;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
  
.inventario-error-message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
  
.inventario-error-message p {
    color: #666;
    text-align: center;
    font-size: 16px;
    max-width: 400px;
    margin: 0;
}

.inventario-loading-message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.inventario-loading-message p {
    color: #666;
    text-align: center;
    font-size: 16px;
    max-width: 400px;
    margin: 0;
}