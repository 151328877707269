.ContainerMainInventario{
    width: 100%;
   margin: 10px;
}
.DivTitleInventario{
    width: 95%;
}
.TitleInventario{
    width: auto;
    text-align: center;
}
.DivTitleTabla{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.itemTabla{
    width: 15%;
    margin-right: 1%;
}
.itemcard{
    display: flex;
    flex-direction: row;
   padding: 4px;
    justify-content: space-around;
    border-radius: 5px;
background: #f9f8f8;
box-shadow:  9px 9px 18px #bebebe,
             -9px -9px 18px #ffffff;
             margin-top: 10px;
             margin-bottom: 20px;

}

.itemelement{
    width: 15%;
    margin-right: 1%;
    align-items: center;
}

.item-text{
    max-width: 80%;
    text-align: left;
}