.input_divido{
    width: 100%;
    display: flex;
    flex-direction: row;
}

.input_divido_children{
 flex: 50%;
 margin-right: 10px;
}
.flex-accordion{
    display: flex;
    flex-direction: column;
    width: 100%;
}