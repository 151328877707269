.container-nuevoequipoia{
    display: flex;
    padding: 10px;
    flex-direction: column

}

.container-inputs-ia{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 100%;

}


.div-inputs-ia{
    margin-top: 10px;
    display: flex;
    width: 90%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
   
}
.div-button-ia{
    margin-top: 15px;
    margin-bottom: 15px;
 
}
.login-input-ia{
    width: 30%;
 
}

.card-equipo-ia{
    border-radius: 50px;
    background: #fcfcfc;
    box-shadow:  15px 15px 40px #bebebe,
                -15px -15px 40px #ffffff;
    padding: 10px;
    width: 230px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
}
.card-equipos-ia-container{
    display: flex;
    flex-direction: row;
    width: 90%;
    margin-top: 15px;
    margin-left: 40px;
    justify-content: space-between;
}
.titulo-equipo-card-ia{
    text-align: center;
    margin-bottom: 1px;
    font-size: 18px;
    line-height: 1.1;
    font-weight:500;
    color:#03178C;
    margin-block-start: 10px;
    margin-block-end: 0;
}

.text-subtitulo-ia{
    text-align: center;
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 1.1;
    font-weight: 400;
    color:#0468BF;
    margin-block-start: 10px;
    margin-block-end: 0;
    margin-left: 10px;

}

.indicador-simlitud-ia{
    text-align: center;
    margin-bottom: 1px;
    font-size: 32px;
    line-height: 1.1;
    font-weight:600;
    color:#03178C;
    margin-block-start: 0.6rem;
    margin-block-end: 0;
}

.container-mensaje-bienvenida{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
}
.text-bienvenida-ia{
    font-size: 24px;
    font-weight:400;
    text-align: center;
    color:#9a9a9a;
    margin-block-start: 0rem;
}