.clienteperfilcontainer {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 15px;
  }
  
  .card-cliente {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px;
    width: 100%;
    height: auto;
    padding: 20px;
    margin: 0 auto 20px;
  }
  
  .div-informacion {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .div-flex-start {
    flex: 1;
    min-width: 300px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .div-imagencliente {
    margin: 0;
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .img-cliente {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: scale-down;
    border: 2px solid #cbd0d6;
  }
  
  .div-infocontainer {
    flex: 1;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .div-titulocliente {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .text-titulo-cliente {
    margin: 0;
    font-size: 25px;
    line-height: 1.2;
    font-weight: 600;
    color: #03178C;
  }
  
  .text-ciudad-cliente {
    margin: 0;
    font-size: 20px;
    line-height: 1.2;
    font-weight: 400;
    color: #0468BF;
  }
  
  .acciones-cliente-perfil {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
  }
  
  .div-container-general {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    gap: 20px;
  }
  
  .div-container-links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    padding: 0 10px;
  }
  
  .link-div-card-link {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px;
    flex: 1;
    min-width: 280px;
    padding: 15px;
    display: flex;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    position: relative;
    z-index: 1;
    background-color: white;
  }
  
  .dashing-cardcontent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 5px;
    position: relative;
    z-index: 2;
  }
  
  .dashing-cardtext {
    flex: 1;
  }
  
  .dashing-text-card {
    margin: 0;
    color: #03178C;
    font-size: 16px;
    font-weight: 500;
  }
  
  .dashing-cardicon,
  .dashing-cardicon-blue,
  .dashing-cardicon-green,
  .dashing-cardicon-orange {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }
  
  .dashing-cardicon {
    background-color: #03178C;
  }
  
  .dashing-cardicon-blue {
    background-color: #0468BF;
  }
  
  .dashing-cardicon-green {
    background-color: #28a745;
  }
  
  .dashing-cardicon-orange {
    background-color: #fd7e14;
  }
  
  .dashing-icon {
    width: 20px;
    height: 20px;
  }
  
  .link-div-card-link:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    transform: translateY(-2px);
  }
  
  .div_boton {
    margin: 10px;
  }
  
  /* Responsive breakpoints */
  @media screen and (max-width: 1200px) {
    .div-container-links {
      justify-content: center;
    }
  
    .link-div-card-link {
      max-width: calc(50% - 20px);
    }
  }
  
  @media screen and (max-width: 768px) {
    .div-informacion {
      flex-direction: column;
      align-items: center;
    }
  
    .div-flex-start {
      width: 100%;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .div-titulocliente {
      flex-direction: column;
      text-align: center;
    }
  
    .text-ciudad-cliente {
      margin: 5px 0 0 0;
    }
  
    .link-div-card-link {
      max-width: 100%;
    }
  
    .card-cliente {
      padding: 15px;
    }
  
    .img-cliente {
      width: 120px;
      height: 120px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .text-titulo-cliente {
      font-size: 22px;
    }
  
    .text-ciudad-cliente {
      font-size: 18px;
    }
  
    .div-imagencliente {
      padding: 10px;
    }
  
    .card-cliente {
      padding: 10px;
    }
  }
  
  /* Utility classes for the container system */
  .div-listadocontainer {
    width: 100%;
    max-width: 1200px;
    margin: 15px auto;
    padding: 0 15px;
  }
  
  .div-tabla-equipos {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }