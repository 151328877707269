.title-recomendaciones {
    color: #0070d1;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-bottom: -35px;
    font-size: 25px;
    font-weight: 575;
    text-align: start;
    padding: 5px;
    text-shadow: #0013243b 1px 1px 20px;
  }
  
  .login-progress-recomendation {
    align-self: center;
    padding: 30px;
  }
  
  .container-recomendaciones {
    display: flex;
    flex-direction: column;
    padding: 10px;
    padding-top: 0px;
  }
  
  .recomendacion-detalle {
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 1.1;
    font-weight: 500;
    color: #0468BF;
    margin-block-start: 10px;
    margin-block-end: 0;
    margin-left: 10px;
  }
  
  .recomendacion-text {
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 1.1;
    font-weight: 400;
    color: #001324;
    margin-block-start: 10px;
    margin-block-end: 0;
    margin-left: 10px;
    padding-bottom: 3px;
  }