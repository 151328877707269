.container-planes{
    padding: 20px 0 60px 0;
    background: linear-gradient(to right, #e2e1e1 0%,#ffffff  100%);
    color: rgba(4, 85, 191, 1)
}

.container-internoplanes{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.seccion-titulo-plan{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    
}
.separador-planes{
    height: 5px;
    width: 100px;
    border: none;
    background: linear-gradient(to right, #0468BF 0%, #0589fc 100%);
}
.titulo-planes{
    margin-block-start: 0;
    margin-bottom: 10px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: #0455BF;
}

.seccion-plan-content{
    width: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 20px;
    justify-content: space-between;

}

.item-plan{
   
    width: 210px;
    height: auto;
    border-radius: 20px;
    background: #e2e1e1;
box-shadow:  -10px -10px 20px #c7c6c6,
             10px 10px 20px #fdfcfc;
}

.item-titulo-plan{
    background-color: #0468bf;
    background-image: linear-gradient(45deg, #0468bf 0%, #2f9eff 100%);
    

    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.item-titulo-h3{
    color: #ffffff;
    font-weight: 500;
    text-align: center;
    margin-block-end: 0;
    margin-block-start: 0;
}

.item-separador-titulo-plan{
    height: 2px;
    width: 90%;
    border: none;
    background: linear-gradient(to right, #59a7ec 0%, #ffffff 100%);
}

.item-parrafo-titulo-plan{
    color: #ffffff;
    text-align: center;
    font-size: 12px;
    margin-block-end: 0;
    margin-block-start: 0;
}

.item-infocontainer-plan{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}
.item-container-precio{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dolar-plan{
    font-size: 26px;
    font-weight: 400;
    margin-block-start: 0;
    margin-block-end: 1em;
}
.precio-plan{
    font-size: 48px;
    font-weight: 700;
    margin-block-start: 0;
    margin-block-end: 0;
}
.duracion-plan{
    font-size: 16px;
    font-weight: 400;
    margin-block-start: 1.8em;
    margin-block-end: 0;
}

.container-lista-ventajas-plan{
    display: flex;
    flex-direction: column;
    margin-top: 5px;
}

.item-ventaja-plan{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.div-icon-ventaja{
    padding: 5px;
    margin: 5px;
}
.item-parrafo-ventaja-plan{
    font-size: 12px;
    margin-block-end: 0;
    margin-block-start: 0;
}

.container-btn-plan{
    margin-top: 10px;
    margin-bottom: 15px;
}

@media screen and (max-width: 960px){
    .item-plan{
        margin-top: 20px;
    }
    .seccion-plan-content{
        align-items: center;
        justify-content: center;
    }
}