.card_curso_recomendado{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  width: 150px;
  margin-right: 20px;
  margin-top: 15px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}
.card_curso_recomendado:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
.div-cursoimagenrecomendado{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 110px;
    background: rgba(0, 176, 255);
}

.cursoimagenrecomendado{
    width: 100%;
    height:110px;
    object-fit: cover;
    opacity: 50%;
}
.div-cursorecomendado-info{
   
    display: flex;
    flex-direction: column;
    padding: 5px;
    width: 90%;
    height: 90px;
}

.div-cursorecomendado-actions{
   
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: 35px;
}
.tittle-curso-recomendado{
    color:#0585f4;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.1;
    font-weight: 500;
    margin-block-start: 5px;
    margin-block-end: 0;
}
.precio-curso-recomendado-antes{
    color: #b3b3b3;
    font-size: 12px;
    line-height: 1.1;
    font-weight: 400;
    margin-block-start: 2px;
    margin-block-end: 0px;
}

.precio-curso-recomendado-ahora{
    color: #808080;
    font-size: 14px;
    line-height: 1.1;
    font-weight: 600;
    margin-block-start: 2px;
    margin-block-end: 0px;
}

.linkbtn-cursos{
    font-size: 14px;
    color: #0585f4;
    font-weight: 500;
    text-decoration: none
}

.linkbtn-cursos:hover {
    color: #808080;
  }