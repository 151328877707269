.cliente-card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto 15px;
    width: 95%;
    max-width: 1200px;
    height: auto;
    align-items: center;
    padding: 15px;
    border-radius: 8px;
    flex-wrap: wrap;
  }
  
  .div-info {
    display: flex;
    flex-direction: row;
    flex: 1;
    min-width: 280px;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .div-cliente-infocontainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 250px;
  }
  
  .imagen-cliente {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: scale-down;
    border: 2px solid #cbd0d6;
    background-color: #f5f5f5;
  }
  
  .div-cliente-imagen {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .div-cliente-titulo {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 15px;
    position: relative;
  }
  
  .cliente-text {
    margin: 5px 0;
    font-size: 16px;
    line-height: 1.4;
    font-weight: 400;
    color: #03178C;
  }
  
  .titulo-cliente-text {
    margin: 0;
    font-size: 20px;
    line-height: 1.2;
    font-weight: 600;
    color: #03178C;
  }
  
  .ciudad-cliente-text {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    color: #0468BF;
  }
  
  .icon-subirimagencliente {
    width: 20px;
    height: 20px;
    color: #03178C;
  }
  
  .div-acciones {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px 0;
  }
  
  .div_cargando {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
  }
  
  .link_nombre_cliente {
    text-decoration: none;
    display: block;
    cursor: pointer;
    position: relative;
    z-index: 1;
  }
  
  .link_nombre_cliente:hover {
    text-decoration: underline;
    color: #0468BF;
  }
  
  /* Responsive breakpoints */
  .div-cliente-imagen button {
    margin-left: 5px;
    align-self: flex-start;
    margin-top: 0;
  }
  
  @media screen and (max-width: 768px) {
    .cliente-card {
      padding: 12px;
      margin-bottom: 12px;
    }
  
    .div-info {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .div-cliente-titulo {
      justify-content: center;
    }
  
    .div-acciones {
      width: 100%;
      justify-content: center;
      margin-top: 15px;
    }
  
    .cliente-text {
      font-size: 14px;
    }
  
    .titulo-cliente-text {
      font-size: 18px;
    }
  
    .ciudad-cliente-text {
      font-size: 14px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .cliente-card {
      width: 92%;
      padding: 10px;
    }
  
    .imagen-cliente {
      width: 80px;
      height: 80px;
    }
  
    .div-cliente-infocontainer {
      min-width: 200px;
    }
  }