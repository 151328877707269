.icon-config {
    font-size: 24px;
    margin-right: 8px;
  }
  
  .icon-formulario {
    font-size: 20px;
  }
  
  /* Estilos para el mensaje de éxito */
  .div-aprobado {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    text-align: center;
  }
  
  .div-icono-aprobado {
    width: 60px;
    height: 60px;
    background-color: #20B90C;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
  }
  
  .icono-aprobado {
    font-size: 28px;
  }
  
  .text-aprobado {
    font-size: 20px;
    color: #333;
    margin: 0;
  }
  
  /* Estilos del formulario */
  .config-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 16px 0;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .form-group label {
    font-size: 14px;
    font-weight: 500;
    color: #333;
  }
  
  /* Inputs de color */
  .color-input-container {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  
  input[type="color"] {
    width: 80px;
    height: 40px;
    padding: 2px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .color-preview {
    width: 36px;
    height: 36px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Select */
  .select-input {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    color: #333;
    background-color: white;
  }
  
  .select-input:focus {
    outline: none;
    border-color: #03178C;
    box-shadow: 0 0 0 2px rgba(3, 23, 140, 0.1);
  }
  
  /* Dropzone */
  .dropzone {
    border: 2px dashed #ccc;
    border-radius: 4px;
    padding: 24px;
    text-align: center;
    transition: all 0.3s ease;
    background-color: #fafafa;
  }
  
  .dropzone.dragging {
    border-color: #03178C;
    background-color: rgba(3, 23, 140, 0.05);
  }
  
  .dropzone-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }
  
  .upload-icon {
    width: 48px;
    height: 48px;
    stroke: #666;
    stroke-width: 2;
    fill: none;
  }
  
  .upload-button {
    color: #03178C;
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    font-weight: 500;
    text-decoration: underline;
  }
  
  .upload-button:hover {
    color: #0425c7;
  }
  
  .upload-text {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 14px;
    color: #666;
  }
  
  .upload-label {
    color: #03178C;
    cursor: pointer;
    font-weight: 500;
  }
  
  .upload-label:hover {
    text-decoration: underline;
  }
  
  .upload-label input {
    display: none;
  }
  
  .file-info {
    font-size: 12px;
    color: #666;
    margin: 0;
  }
  
  .selected-file {
    font-size: 14px;
    color: #333;
    margin: 8px 0 0;
    word-break: break-all;
  }
  
  /* Mensajes de error */
  .error-message {
    color: #dc2626;
    font-size: 12px;
    margin: 4px 0 0;
  }
  
  .general-error {
    margin-top: 16px;
    padding: 8px;
    background-color: #fee2e2;
    border-radius: 4px;
  }
  
  /* Progress spinner */
  .progress {
    margin: 0 8px;
  }
  
  /* Dialog Actions */
  .MuiDialogActions-root {
    padding: 16px 24px;
    background-color: #f9fafb;
    border-top: 1px solid #e5e7eb;
  }
  
  /* Dialog Content */
  .MuiDialogContent-root {
    padding: 20px 24px;
  }
  
  /* Dialog Title */
  .MuiDialogTitle-root {
    background-color: white;
    border-bottom: 1px solid #e5e7eb;
  }
  
  /* Botones */
  .MuiButton-root {
    text-transform: none;
    font-weight: 500;
    padding: 6px 16px;
  }
  
  /* Estilos para el estado de hover en elementos interactivos */
  .upload-label:hover,
  .select-input:hover,
  input[type="color"]:hover {
    opacity: 0.9;
  }
  
  /* Estilos para el estado activo/focus */
  .select-input:focus,
  input[type="color"]:focus {
    outline: none;
    border-color: #03178C;
  }
  
  /* Estilos para estados deshabilitados */
  .select-input:disabled,
  input[type="color"]:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  /* Animación del spinner */
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  .progress {
    animation: spin 1s linear infinite;
  }