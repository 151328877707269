.div-agregarruta-info{
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    display: flex;
}

.text-card-agregar-ruta{
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    margin-block-start: 5px;
    margin-block-end: 0px;
    color: #a9a9a9;
}