.navbar {
    background: linear-gradient(#ffffff, #cbd0d6);
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: fixed;
    top: 0;
    z-index: 999;
  }
   
  .navbar-container {
    display: flex;
    justify-content: space-between;
    height: 80px;
  }
  
  .container {
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 50px;
    padding-left: 50px;
  }
  
  .navbar-logo {
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  .logonavbar{
    margin-right: 0.5rem;
    margin-top: -15px;
    margin-left:5px
  }
  
  .navbar-icon {
    margin-right: 0.5rem;
  }
  
  .nav-menudash {
    display: flex;
    align-items: center;
   
    list-style: none;
    text-align: center;
   
  }
  
  .nav-item {
    height: 80px;
    border-bottom: 2px solid transparent;
    margin-bottom: 3%;
  }
  
  .nav-item:hover {
    border-bottom: 2px solid #03178C;
  }
  
  .nav-links {
    color: #03178C;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  
  }
  
  .fa-bars {
    color: #03178C;
  }
  
  .menu-icon {
    display: none;
  }

  .nav-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
    margin-bottom: 3%;
  }
  
  @media screen and (max-width: 960px) {
    .container {
        z-index: 1;
        width: 99.67%;
        max-width: 1300px;
        margin-right: auto;
        margin-left: auto;
        padding-right: 50px;
        padding-left: 50px;
      }
    .NavbarItems {
      position: relative;
    }
  
    .nav-menudash {
    display: flex;
      flex-direction: column;
      width: 100%;
      height: 92vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
      padding-inline-start: 0px;
    }

    .drawer-desplegado{
        background: linear-gradient(#ffffff, #cbd0d6);
      padding-inline-start: 0px;
      width: 100%;
      max-width: 100%;
      left:0; 
      opacity: 1;
    }
   
  
    .nav-menudash.active {
      background: linear-gradient(#ffffff, #cbd0d6);
      padding-inline-start: 0px;
      width: 100%;
      max-width: 100%;
      left:0; 
      opacity: 1;
      
    }
  
    .container-navbar{
width: 100%;
height: 200px;
  }
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      color: #f00946;
      transform: scale(1.2);
      transition: all 0.3s ease;
    }
  
    .nav-item:hover {
      border: none;
    }
  
    .nav-item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-right: 9%;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(1%, 1%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #03178C;
      font-size: 2rem;
    }
  
    .nav-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 120px;
    }
  }
  
  @media screen and (max-width: 460px) {
    .container {
        z-index: 1;
        width: 99.67%;
        max-width: 1300px;
        margin-right: auto;
        margin-left: auto;
        padding-right: 50px;
        padding-left: 50px;
      }
    .NavbarItems {
      position: relative;
    }

    .drawer{
        margin-top: 64px;
        width: 50%;
        color: red;
    }
    .drawer-desplegado{
        background: linear-gradient(#ffffff, #cbd0d6);
      padding-inline-start: 0px;
      width: 100%;
      max-width: 100%;
      left:0; 
      opacity: 1;
    }
    .nav-menudash {
    display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 60px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
      padding-inline-start: 0px;
    }
  
    .nav-menudash.active {
      background: linear-gradient(#ffffff, #cbd0d6);
      padding-inline-start: 0px;
      width: 100%;
      max-width: 100%;
      left:0; 
      opacity: 1;
      
    }
    .icon-logout{
        color:#03178C;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      color: #f00946;
      transform: scale(1.2);
      transition: all 0.3s ease;
    }
  
    .nav-item:hover {
      border: none;
    }
  
    .nav-item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-right: 15%;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      margin-top: 5px;
      margin-left:5px;    
      transform: translate(1%, 1%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #03178C;
      font-size: 2rem;
    }
  
    .nav-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 120px;
    }
  }
