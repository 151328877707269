#register{
    height: 100%;
    width: 100%;
   position: fixed;
     background: linear-gradient(to right, #e2e1e1 0%,#ffffff  100%);
     color: rgba(4, 85, 191, 1)
 }

 
.register-container{
    padding: 10px 0;
      display: flex;
      align-content: center;
      justify-content: center;
      
  }

  .register-card{
    height: 100%;
    width: 430px;
  
    border-radius: 8px;
    background: linear-gradient(to left, #ffffff, #e6e6e6);
    box-shadow:  -12px 12px 24px #c7c7c7, 
                 12px -12px 24px #ffffff;
  }
 