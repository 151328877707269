.div-info-docs{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.div-doc-infocontainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;
}

.doc-titulo-text{
    margin-bottom: 10px;
        font-size: 22px;
        line-height: 1.1;
        font-weight: 600;
        color:#03178C;
        margin-block-start: 10px;
        margin-block-end: 0;
}
.div-doc-titulo{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.doc-text{
    margin-left: 10px;
    font-size: 18px;
    line-height: 1.1;
    font-weight: 400;
    color:#0468BF;
    margin-block-start: 10px;
    margin-block-end: 0;
    
}

.doc-descripcion-text{
    margin-left: 0;
    font-size: 18px;
    line-height: 1.1;
    font-weight: 450;
    color:#03178C;
    margin-block-start: 5px;
    margin-block-end: 0;
}

.doc-link-text{
    margin-left: 0;
    margin-top: 5px;
    font-size: 16px;
    line-height: 1.1;
    font-weight: 400;
    color:#203bd7;
    margin-block-start: 5px;
    margin-block-end: 0;
}

.doc-link-text-link{
    margin-left: 0;
    margin-top: 5px;
    font-size: 16px;
    line-height: 1.1;
    font-weight: 400;
    color:#203bd7;
    margin-block-start: 5px;
    margin-block-end: 0;
    text-decoration: none;
}