.card_marketplace{
    max-width: 300px;
    margin-right: 20px;
    margin-top: 10px;
}
.imagen-card{
    height: 140px;
}

.imagen-card-filter{
    height: 140px;
    background-color: rgba(0, 176, 255, 0.3);
}
.market_categorias_container{
    width: 95%;
    margin-left: 20px;
    display: flex;  
    flex-direction: column;
  
}
.market_container{
    width: 100%;
}
.tittle-marketplace{
    color:#0585f4;
}

.dashing-card-market{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px;
    height: 120px;
    width: 100%;
    margin-right: 20px;
    margin-top: 20px;
    align-content: center;
    align-items: center;
}

.dashing-card-market:hover {
    cursor: pointer;
    
  }

.dashing-cardcontainer-market{
    padding: 10px;
   display: flex;
   height: 75%;  
    align-items: center;
    align-content: center;
  
}
.linkclass-market{
    text-decoration: none;
}

.subcontainer-market{
    width: 100%;
    display: flex;
    flex-direction: row;
    
}