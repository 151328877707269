.cal_pdf_container {
    width: 100%;
    height: 100vh;
    padding: 20px;
  }
  
  .cal_pdf_header {
    margin-bottom: 20px;
  }
  
  .cal_pdf_toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .cal_pdf_left_section {
    display: flex;
    align-items: center;
  }
  
  .cal_pdf_right_section {
    display: flex;
    gap: 10px;
  }
  
  .cal_pdf_back_icon {
    width: 24px;
    height: 24px;
  }
  
  .cal_pdf_view_btn {
    text-transform: none !important;
    font-size: 14px !important;
  }
  
  .cal_pdf_error {
    text-align: center;
    padding: 20px;
    color: #dc3545;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
    margin: 20px 0;
  }
  
  .cal_pdf_content {
    height: calc(100vh - 100px);
    background-color: #f5f5f5;
    border-radius: 8px;
    overflow: hidden;
  }