.main-container-asistencial{
    width: 95%;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

.dashing-div-secondindicators-asistencial{
    width: 95%;
    display: flex;
    flex-direction: column;
}

.dashing-card-doble-asistencial{
    display: flex;
    flex-direction: row;
    
}

.dashing-card-data-center{
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-dashboard-asis{
    padding: 8px;
}