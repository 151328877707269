.listamtoprog-table{
    width: 95%;
    position: relative;
    margin-left: 5px;
}

.div-input-doble-mtoprog{
    display: flex;
    flex: 45%;
    flex-direction: row;
    margin-top: 5px;
    
    
}

.formcontrol-nuevomto{
width: 100%;
    
}
.div-mediciones-container{
    display: flex;
    flex-direction: row;
    flex: content;
}
.listamtoprog-container{
    width: 95%;
    margin-left: 10px;
}

.div-aprobado{
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.div-icono-aprobado{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: #20B90C;
    border-radius: 50%;
    width: 150px;
    height: 150px;
}

.div-icono-delete{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: #f9044e;
    border-radius: 50%;
    width: 150px;
    height: 150px;
}

.div-icono-advertencia{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: #ffd631;
    border-radius: 50%;
    width: 150px;
    height: 150px;
}
.icono-aprobado{
    height: 100px;
    width: 100px;
}
.text-aprobado{
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    color: #a3a3a3;
}