.market_container{
    min-width: 100%;
    width: 100%;
}

.titulomarket_section{
    min-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
}
.titulo_market{
    margin:10px;
}
.divmarket_boton{
    margin:10px;
}
