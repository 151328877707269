#login{
   height: 100%;
   width: 100%;
  position: fixed;
    background: linear-gradient(to right, #e2e1e1 0%,#ffffff  100%);
    color: rgba(4, 85, 191, 1)
}


.login-container{
  padding: 100px 0;
    display: flex;
    align-content: center;
    justify-content: center;
    
}
.login-card{    
    height: 460px;
    width: 430px;
  
    border-radius: 8px;
    background: linear-gradient(to left, #ffffff, #e6e6e6);
    box-shadow:  -12px 12px 24px #c7c7c7, 
                 12px -12px 24px #ffffff;
    
}
.login-divtitulo{
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
}
.login-progress{
    align-self: center;
}
.login-logo{
    width: 90%;
    margin-top: 20px;
 
}
.login-titulo{
    margin-bottom: 10px;
    font-size: 32px;
    line-height: 1.1;
    font-weight: 600;
    color: #03178C;
    margin-block-start: 0;
}
.login-form{
    display: flex;
    flex-direction: column;
    padding: 10px 10px;
    margin-top: 10px;

}
.login-input-email{
    display: block;
    width: 90%;
    padding: 6px 12px;
    font-size: 16px;
    line-height: 1.42857143;
    color: #7f7f7f;
    background-image: none;
    border: 1px solid #ddd;
    border-radius: none;
    box-shadow: none;
    transition: none;
    
}
.login-input-contraseña{
    display: block;
    width: 90%;
    padding: 6px 12px;
    font-size: 16px;
    line-height: 1.42857143;
    color: #444;
    background-image: none;
    border: 1px solid #ddd;
    border-radius: 0;
    box-shadow: none;
    transition: none;
    
}
.login-btn{
  
   	font-size: 18px;
	font-weight: 500;
    border-radius: 25px;
    border: none;
}
.login-text{
    text-align: center;
    margin-bottom: 10px;
}
.login-perror{
    color: red;
    font-size: 18px;
    margin-top: 5px;
    margin-bottom: 5px;
}