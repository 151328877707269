#dashboard-ingeniero{
    padding: 0px 25px;

}
.dashing-card{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px;
    height: 90px;
    width: 100%;
    margin-right: 20px;
    margin-top: 20px;
    align-content: center;
    align-items: center;
}
.dashing-div-card{
    margin-right: 10px;
    flex:20%;
}
.dashign-div-secondcard{
    flex:40%;
    margin-right: 10px;
}
.dashing-container{
    display: flex;
    flex-direction: column;
  
   
}

.dashing-rowindicators{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}

.dashing-icon{
    height: 30px;
    width: 30px;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
}
.dashing-cardicon{
    background: linear-gradient(to right, #0455BF 0%, #1276f8 100%);
	box-shadow: 10px 10px 10px rgba(0,0,0,.05);
    padding:5px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.dashing-cardcontent{
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.dashing-cardunder{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
    margin-top: 20px;
    
}
.link-card{
    text-decoration: none;
}
.dashing-textunder{
    margin-block-start: 0;
    margin-block-end: 0;
    color: green;
    font-size: 13px;
    font-weight: 600;
}
.dashing-value-card{
    font-size: 26px;
    font-weight: 600;
    color:#03178C;
    margin-block-start: 0;
    margin-block-end: 0;
}
.dashing-span{
    margin-left: 5px;
    font-size: 13px;
    font-weight: 300;
}


.dashing-div-secondindicators{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}


.dashing-chart-icon{
    height: 25px;
    width: 25px;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;

}
.dashing-card-data{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-right: 10px;
}

.dashing-text-data{
    font-size: 11px;
    font-weight: 500;
    color:#03178C;
    margin-block-start: 0;
    margin-block-end: 0;

}
.dashing-value-data-green{
    font-size: 20px;
    font-weight: 700;
    color:#18941C;
    margin-block-start: 0;
    margin-block-end: 0;
}



.dashing-value-data-red{
    font-size: 20px;
    font-weight: 700;
    color:#E0231C;
    margin-block-start: 0;
    margin-block-end: 0;
}

.dashing-value-data-yellow{
    font-size: 20px;
    font-weight: 700;
    color:#F2AC29;
    margin-block-start: 0;
    margin-block-end: 0;
}
.dashing-value-data-blue{
    font-size: 20px;
    font-weight: 700;
    color:#0455BF;
    margin-block-start: 0;
    margin-block-end: 0;
}



.dashing-cardicon-orange{
    background: linear-gradient(to right, #db681a 0%, #F2711D 100%);
	box-shadow: 10px 10px 10px rgba(0,0,0,.05);
    padding:5px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}



.dashing-cardicon-green{
    background: linear-gradient(to right, #1ea70b 0%, #21BF0D 100%);
	box-shadow: 10px 10px 10px rgba(0,0,0,.05);
    padding:5px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.dashing-cardicon-blue{
    background: linear-gradient(to right,#0697D6 0%,#06C4CC 100%);
	box-shadow: 10px 10px 10px rgba(0,0,0,.05);
    padding:5px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.dashing-cardicon-red{
    background: linear-gradient(to right,#ff0101 0%,#bd0505 100%);
	box-shadow: 10px 10px 10px rgba(0,0,0,.05);
    padding:5px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.dashing-recomendations{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.dashing-divcard-recomendations{
    display: flex;
    flex-direction: column;
    flex:45%;
    margin-right: 10px;
    
}
.dashing-tittlecard-recomendations{
    margin-top: 0 px;
    padding: 10px 10px;
    display: flex;
    flex-direction: column;
}
.dashing-recomendations-card{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px;
    height: 450px;
    width: 100%;
    margin-right: 10px;
    margin-top: 20px;
}

.equipo-image{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-top: 0px;
    vertical-align: middle;
    object-fit: cover;
    border-color:#cbd0d6;
    border-style: solid;
    border-width: 2px;
}
.dashing-equipo-container{
    padding: 5px 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dashing-equipo-card{
    
    border-bottom-style: solid;
    border-top-style: solid;
    border-color: #e2e2e2;
    border-width: 0.5px;
   
}

.dashing-equipo-h4{
    font-size: 18px;
    font-weight: 700;
    color:#0455BF;
    margin-block-start: 0;
    margin-block-end: 0;
}

.dashing-equipo-p{
    font-size: 14px;
    font-weight: 300;
    color:#0455BF;
    margin-block-start: 0;
    margin-block-end: 0;
}
.dashing-equipo-text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;
}
.dashing-vermascontainer{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 5 px;
    padding: 10px 10px;

}

.dashing-btn-link{
    align-items: flex-end;
}

.dashing-secondindicators {
    display: flex;
    flex-direction: column;
  
    width: 90%;
    flex-wrap: wrap;
}

/* Card de estado */
.dashing-cardestado {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px;
    height: 270px;
    flex: 1 1 20%;
    width: 90%;
    margin-right: 10px;
    margin-top: 20px;
}

/* Container del card */
.dashing-cardcontainer {
    padding: 10px;
    align-items: center;
    align-content: center;
}

/* Texto del card */
.dashing-text-card {
    font-size: 18px;
    font-weight: 500;
    color: #03178C;
    margin-block-start: 0;
    margin-block-end: 0;
}

/* Container del chart */
.dashing-chart-container {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: 100%;
    max-height: 350px;
}

/* Container de datos del card */
.dashing-card-data-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

@media (min-width: 1080px) {
    .dashing-secondindicators {
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: nowrap;
        width: 100%;
    }
    .dashing-cardestado {
        width: 23%;
        margin-right: 10px;
        margin-top: 15px;
    }
}

@media (max-width: 1080px) {
    .dashing-card {
        height: auto;
    }
    .dashing-div-card,
    .dashign-div-secondcard {
        flex: 1 1 100%;
        margin-right: 0;
    }
    .dashing-secondindicators {
        flex-direction: column;
        width: 100%;
    }
    .dashing-cardestado {
        flex: 1 1 100%;
        width: 100%;
        margin-right: 0;
    }
}

@media (max-width: 768px) {
    .dashing-cardicon {
        width: 30px;
        height: 30px;
    }
    .dashing-value-card,
    .dashing-text-card {
        font-size: 20px;
    }
    .dashing-value-data-green,
    .dashing-value-data-red,
    .dashing-value-data-yellow,
    .dashing-value-data-blue {
        font-size: 16px;
    }
    .dashing-text-data {
        font-size: 10px;
    }
}

@media (max-width: 576px) {
    .dashing-cardcontainer {
        padding: 5px;
    }
    .dashing-card-data-container {
        flex-direction: column;
    }
    .dashing-card {
        height: auto;
        margin-right: 0;
    }
}