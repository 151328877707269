#contacto{
    padding: 20px 0 60px 0;
    background: linear-gradient(to right, #e2e1e1 0%,#ffffff  100%);
    color: rgba(4, 85, 191, 1)
}
.contact-container{
display: flex;
flex-direction: column;
padding: 15px;
}
.form-container{
    flex:70%;
    width: 80%;
    padding:0px 15px
 
 }
 .info-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    flex: 30% 1;
    flex-direction: row;
    width: 80%;
    padding-left: 5px;
}
.form-display{
    display: flex;
    flex-direction: column;
}
.form-titulo{
    margin-block-start: 0;
        margin-bottom: 10px;
        font-size: 48px;
        line-height: 1.1;
        font-weight: 600;
        color: #0455BF;
}
.form-separador{
    height: 5px;
    margin-left: 1px;
    width: 100px;
    border: none;
    background: linear-gradient(to right, #0468BF 0%, #0589fc 100%);
}
.form-form{
    display: flex;
    flex-direction: column;
}

.form-fila{
    display: flex;
    flex-direction: row;
}
.form-inputname{
    display: block;
    width: 90%;
    padding: 6px 12px;
    font-size: 16px;
    line-height: 1.42857143;
    color: #444;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ddd;
    border-radius: 0;
    box-shadow: none;
    transition: none;
}
.form-inputemail{
    display: block;
    width: 90%;
    padding: 6px 12px;
    font-size: 16px;
    line-height: 1.42857143;
    color: #444;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ddd;
    border-radius: 0;
    box-shadow: none;
    transition: none;
}
.form-divinputname{
    flex: 50%;
    margin-bottom: 15px;
}
.form-divinputemail{
    flex: 50%;
    margin-left: 50px;
    margin-bottom: 15px;
}
.form-divinputtext{
    width: 95%;

}
.form-textarea{
    display: block;
    width: 100%;
    padding: 6px 12px;
    font-size: 16px;
    line-height: 1.42857143;
    color: #444;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ddd;
    border-radius: 0;
    box-shadow: none;
    transition: none;
    font-family: 'Courier New', Courier, monospace;
}
.div-button{
    margin-top: 10px;
}
.contact-spanitem{
    margin-bottom: 10px;
    display: block;
    font-size: 20px;
    font-weight: 500;
    align-items: center;
    align-content: center;
}
.contact-tituloinfo{
    font-size: 30px;
}
.contacticon{
    width: 20px;
    height: 20px;
    margin-right: 10px;
}
.contact-pitem{
    font-size: 15px;
    
}
.contact-socialcontainer{
    padding: 20px 0;
}
.contact-socialrow{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
}
.contact-li{
   display: inline-block;
   margin: 0 20px;
   

}

.contact-socialicon{
   width: 40px;
   height: 40px;
   padding:2px 2px; 
   margin:3px 3px;
    
    color: #0468BF;
   
}
.contact-bordersocial{
    
    width: 48px;
    height: 48px;
    padding: 2px 2px;
    border: 2px solid #0468BF;
    border-radius: 50%;
    transition: all 0.3s;
}
#footer{
    padding: 30px 0;
}
@media screen and (max-width: 412px){
    .contact-container {
        display: flex;
        flex-direction: column;
        width: 99%;
    }

    .info-container{
        display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    flex: 30% 1;
    flex-direction: column;
    width: 80%;
    padding-left: 5px;
        
    }
 }