.text-equipo-subtitulos{
    margin-bottom: 1px;
    font-size: 18px;
    line-height: 1.1;
    font-weight:500;
    color:#03178C;
    margin-block-start: 1px;
    margin-block-end: 0;
}

.equipo-detalle{
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 1.1;
    font-weight: 500;
    color:#03178C;
    margin-block-start: 10px;
    margin-block-end: 0;
}
.div-equipo-info{
    width: 100%;
}

.div-perfil-info{
    display: flex;
    width: 90%;
    flex-direction: row;
    flex-wrap: wrap;
   margin-bottom: 10px;
   align-content: space-between;
  
 
}
.div-perfil-detalle{
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    margin-right: 15px;
}
.perfilequipo-text{
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 1.1;
    font-weight: 400;
    color:#0468BF;
    margin-block-start: 10px;
    margin-block-end: 0;
    margin-left: 10px;
}

.card-equipo{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  
    border-radius: 5px;
    width: 95%;
    height: auto;
    margin-left: 20px;
    margin-top: 10px;
}

.div-acciones-equipo{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.acordion-container-equipo{
    border-radius: 5px;
    width: 95%;
    height: auto;
    margin-left: 20px;
    margin-top: 10px;
}

.equipment-profile {
    max-width: 1400px;
    margin: 0 auto;
    padding: 1rem;
  }
  
  .equipment-profile__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .equipment-profile__title {
    flex: 1;
  }
  
  .equipment-profile__title h1 {
    color: #03178C;
    font-size: 1.75rem;
    margin: 0;
  }
  
  .equipment-profile__title hr {
    border: none;
    border-top: 2px solid #03178C;
    margin: 1rem 0;
  }
  
  .equipment-profile__back-btn {
    background: none;
    border: none;
    color: #03178C;
    cursor: pointer;
    font-size: 1.5rem;
    padding: 0.5rem;
  }
  
  .equipment-card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
    padding: 1.5rem;
  }
  
  .equipment-card__content {
    display: flex;
    gap: 2rem;
  }
  
  .equipment-card__image-container {
    flex: 0 0 250px;
  }
  
  .equipment-card__image {
    width: 100%;
    height: auto;
    border-radius: 4px;
    object-fit: cover;
  }
  
  .equipment-card__info {
    flex: 1;
    min-width: 0;
  }
  
  .equipment-card__header {
    margin-bottom: 1.5rem;
  }
  
  .equipment-card__name {
    color: #03178C;
    font-size: 1.5rem;
    margin: 0 0 0.5rem;
  }
  
  .equipment-card__status,
  .equipment-card__id {
    color: #0468BF;
    font-size: 1.125rem;
    margin: 0.25rem 0;
  }
  
  .equipment-card__details {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 1rem;
  }
  
  .detail-row {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .detail-row__label {
    color: #03178C;
    font-weight: 500;
    min-width: 120px;
  }
  
  .detail-row__value {
    color: #0468BF;
  }
  
  .equipment-card__actions {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
  }
  
  .action-btn {
    min-width: 160px !important;
    text-transform: none !important;
  }
  
  .action-btn--active {
    background-color: #0588FB !important;
    color: white !important;
  }
  
  .equipment-profile__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
  }
  
  /* Responsive Breakpoints */
  @media (max-width: 1024px) {
    .equipment-card {
      margin: 1rem;
    }
    
    .equipment-card__details {
      grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    }
  }
  
  @media (max-width: 768px) {
    .equipment-card__content {
      flex-direction: column;
    }
    
    .equipment-card__image-container {
      max-width: 100%;
      margin: 0 auto;
    }
    
    .action-btn {
      width: 100% !important;
    }
  }
  
  @media (max-width: 480px) {
    .equipment-profile__header {
      flex-direction: column;
      text-align: center;
    }
    
    .equipment-profile__back-btn {
      margin-top: 1rem;
    }
    
    .equipment-card__name {
      font-size: 1.25rem;
    }
    
    .equipment-card {
      padding: 1rem;
    }
  }

  .equipment-actions-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    margin: 1rem auto;
    max-width: 1400px;
    width: 95%;
  }
  
  .equipment-actions-grid .MuiButton-root {
    min-width: 180px;
    padding: 0.5rem 1rem;
    margin: 0;
    flex: 0 1 auto;
    white-space: nowrap;
    text-transform: none;
    transition: all 0.3s ease;
  }
  
  .equipment-actions-grid .action-icon {
    margin-left: 0.5rem;
    font-size: 1.2rem;
  }
  
  /* Responsive breakpoints */
  @media (max-width: 1200px) {
    .equipment-actions-grid {
      width: 98%;
      gap: 0.75rem;
    }
  
    .equipment-actions-grid .MuiButton-root {
      min-width: 160px;
    }
  }
  
  @media (max-width: 992px) {
    .equipment-actions-grid {
      padding: 1rem;
    }
  
    .equipment-actions-grid .MuiButton-root {
      min-width: 140px;
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 768px) {
    .equipment-actions-grid {
      flex-direction: row;
      justify-content: flex-start;
      overflow-x: auto;
      padding: 1rem 0.5rem;
      gap: 0.5rem;
    }
  
    .equipment-actions-grid .MuiButton-root {
      min-width: auto;
      padding: 0.5rem 0.75rem;
    }
  }
  
  @media (max-width: 480px) {
    .equipment-actions-grid {
      flex-direction: column;
      align-items: stretch;
      width: 100%;
    }
  
    .equipment-actions-grid .MuiButton-root {
      width: 100%;
    }
  }

  .equipment-img__container{
    align-items: center;
    justify-content: center;
    justify-items: center;
   

  }