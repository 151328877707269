.contenedor-secundario{
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    justify-content: center;
}
.div-boton-consultar{
    margin-top: 15px;
  

}