.hoja-vida-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}

.hoja-vida-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  margin-bottom: 10px;
}

.hoja-vida-back-btn {
  display: flex;
  align-items: center;
}

.hoja-vida-back-icon {
  color: #03178C;
  font-size: 24px;
}

.hoja-vida-content {
  width: 100%;
  height: calc(100vh - 80px);
}

.hoja-vida-error {
  padding: 20px;
  text-align: center;
  color: #666;
}

.hoja-vida-pdf-viewer {
  width: 100%;
  height: 90vh;
}