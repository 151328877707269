.div_container_perfil {
    width: 95%;
    height: 100%;
    margin: 0 auto; /* Center the container */
    max-width: 1200px; /* Limit maximum width */
}

.div_card_perfil {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    min-height: 600px; /* Changed from fixed height */
    height: auto;
    transition: 0.3s;
    margin: 10px auto; /* Center the card and consistent margins */
    border-radius: 5px;
    background: linear-gradient(30deg, #0468BF 0%, rgba(3,23,140,1) 9%, #0589fc 100%);
    display: flex;
    flex-direction: column;
    padding: 20px; /* Add padding for content */
}

.div_card_content {
    width: 100%;
    height: auto; /* Changed from fixed height */
    min-height: 500px;
    background: linear-gradient(145deg, #e6e6e6, #ffffff);
    border-radius: 0 0 5px 5px;
    padding: 20px;
}

.perfil-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    vertical-align: middle;
    object-fit: scale-down;
    border: 2px solid #cbd0d6;
}

.div_img_perfil {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    flex-direction: row;
    gap: 15px; /* Espacio entre la imagen y el botón */
}

.div_perfil_titulo {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    text-align: center;
    padding: 10px;
}

.div_nombre_perfil {
    margin-bottom: 10px;
    font-size: clamp(24px, 4vw, 30px); /* Responsive font size */
    line-height: 1.1;
    font-weight: 600;
    color: #03178C;
    margin-block-start: 10px;
    margin-block-end: 0;
}

.div_nombre_handler {
    font-size: clamp(20px, 3vw, 25px);
    line-height: 1.1;
    font-weight: 400;
    color: #0468BF;
    margin-block-start: 1px;
    margin-block-end: 0;
}

.div_text_info_perfil {
    margin-left: 10px;
    font-size: clamp(16px, 2.5vw, 22px);
    line-height: 1.1;
    font-weight: 400;
    color: #0468BF;
    margin-block-start: 1px;
    margin-block-end: 0;
    word-break: break-word; /* Prevent text overflow */
}

.div_info_perfil {
    display: flex;
    margin-top: 20px;
    flex-direction: row;
    align-items: center;
    gap: 10px; /* Add space between icon and text */
}

.div_perfil_informacion {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    justify-content: center;
    gap: 20px;
}

.div_perfil_infocolumna {
    flex: 1;
    max-width: 400px;
}

.perfil-icon {
    height: 25px;
    width: 25px;
    flex-shrink: 0;
    cursor: pointer;
}

/* Media Queries for Responsive Design */
@media screen and (max-width: 768px) {
    .div_perfil_informacion {
        flex-direction: column;
        align-items: center;
    }

    .div_perfil_infocolumna {
        width: 100%;
        margin-left: 0;
        padding: 0 10px;
    }

    .div_card_perfil {
        margin: 10px;
        padding: 10px;
    }
}

@media screen and (max-width: 480px) {
    .div_container_perfil {
        width: 100%;
    }

    .div_info_perfil {
        margin-top: 15px;
    }

    .perfil-image {
        width: 120px;
        height: 120px;
    }
    
    .div_img_perfil {
        gap: 10px; /* Reducimos el espacio en móvil */
    }
}