.mto-pdf-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
  }
  
  .mto-pdf-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    margin-bottom: 10px;
  }
  
  .mto-pdf-back-btn {
    display: flex;
    align-items: center;
  }
  
  .mto-pdf-back-icon {
    color: #03178C;
    font-size: 24px;
  }
  
  .mto-pdf-content {
    width: 100%;
    height: calc(100vh - 80px);
  }
  
  .mto-error-message {
    padding: 20px;
    text-align: center;
    color: #666;
  }