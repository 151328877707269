.tittle-cursos{
    color:#0585f4;
    margin-bottom: 15px;
    font-size: 20px;
    line-height: 1.1;
    font-weight: 550;
    margin-block-start: 10px;
    margin-block-end: 0;
}

.text-recomendaciones-cursos{
    color:#a9a9a9;
    font-size: 16px;
    line-height: 1.1;
    font-weight: 550;
    margin-block-start: 5px;
    margin-block-end: 0;
}
.card_cursos{
    max-width: 250px;
    margin-right: 20px;
    margin-top: 15px;
}

.cursos_container{
    display: flex;
    flex-direction: row;

}

.cursos_categorias_container{
    display:flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 10px;
    margin-left: 15px;
}

.clientes_separator_cursos{
    height: 1px;
    width:100%;
    border: none;
    background: #a9a9a9;
}