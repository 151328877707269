.div-container-tecno-prestador {
    display: flex;
    flex-direction: row;
    justify-content: end;
    width: 100%;
    padding-bottom: 10px;
  }
  
  .input_divido_tecno_cliente {
    width: 50%;
    display: flex;
    flex-direction: row;
  }
  
  .input_divido_children_tecno {
    flex: 30%;
    margin-right: 10px;
  }
  
  .input_divido_tecno_boton {
    flex: 20%;
    align-content: center;
    text-align: center;
  }