#herramientas {
    padding: 50px 0;
    background: linear-gradient(to right, #0455BF 0%, #1276f8 100%);
    color: #fff;
}
.text-center {
    text-align: center;
}

.containerherramientas{
    width: 100%;
    
    margin-right: auto;
    margin-left: auto;
}
.separadorherramientas{
    height: 5px;
    width: 100px;
    border: none;
    background: linear-gradient(to right, #0468BF 0%, #0589fc 100%);
}
.tituloherramientas{
    margin-bottom: 10px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: #f7f8fa;
}
.parrafoherramientas{
    color:rgba(255,255,255,.75);
    font-size: 18px;
}
.flex-container-herramientas{
    display: flex;
    flex-direction: column;
}
.row-herramientas{
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}
.div-herramienta{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    width: 33%;
}
.caract-herramientas{
    font-size: 38px;
    margin-bottom: 0px;
	transition: all 0.5s;
	color: #fff;
    width: 100px;
	height: 100px;
	padding: 30px 30px;
	border-radius: 50%;
	background: linear-gradient(to right, #0455BF 0%, #1276f8 100%);
	box-shadow: 10px 10px 10px rgba(0,0,0,.05);
}

@media screen and (max-width: 412px){
    .desc-herramientas{
        color:rgba(255,255,255,.75);
    font-size: 18px;
    width: 80%;
    margin: 15px 15px;
    }
    .containerherramientas {
        width: 100%;
        padding-right: 0px;
        padding-left: 0px;
        margin-right: 0px;
        margin-left: 0px;
        display: flex;
        flex-direction: column;
    }
    .div-herramienta {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 10px;
        margin-right: 10px;
        width: 100%;
    }
    .secciontitulo{
        display: flex;
         flex-direction: column;
        justify-content: center;
        margin-bottom: 50px;
    }
    .row-herramientas {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }
    .parrafoherramientas {
        color: rgba(255,255,255,.75);
        font-size: 18px;
        padding: 5px 15px;
    }
}
@media screen and (max-width: 769px){
    .desc-herramientas{
        color:rgba(255,255,255,.75);
    font-size: 18px;
    width: 80%;
    margin: 15px 15px;
    }
    .containerherramientas {
        width: 100%;
        padding-right: 0px;
        padding-left: 0px;
        margin-right: 0px;
        margin-left: 0px;
        display: flex;
        flex-direction: column;
    }
    .div-herramienta {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 10px;
        margin-right: 10px;
        width: 100%;
    }
    .secciontitulo{
        display: flex;
         flex-direction: column;
        justify-content: center;
        margin-bottom: 50px;
    }
    .row-herramientas {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }
    .parrafoherramientas {
        color: rgba(255,255,255,.75);
        font-size: 18px;
        padding: 5px 15px;
    }
}