.container-market-item{
    display: flex;
    flex-direction: column;
}

.error-respuesta{
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 1.1;
    font-weight: 400;
    color:#f51010;
    margin-block-start: 10px;
    margin-block-end: 0;
}