.layout-container{
    margin-top: 80px;
    display: flex;
    flex-direction: row;
}
.children-container{
    margin-left: 250px;
    width: 80%;
}


@media screen and (max-width: 960px){
    .children-container{
        margin-left: 10px;
        width: 95%;
    }
    
}
@media screen and (max-width: 412px){
    .children-container{
        margin-left: 10px;
        width: 95%;
    }
    
}
