.div-container-info-ind-mtoifra{
    display: flex;
    flex-direction: row;
    padding: 5px;
    margin-left: 5px;
    align-items: center;
    
}
.div-texto-info-ind-mtoinfra{
    display: flex;
    flex-direction: row;
    margin-left: 5px;
    justify-content: space-between;
    
    width: 100%;
}
.text-info-ind-mtoinfra{
    font-size: 16px;
    font-weight: 500;
    color:#03178C;
    margin-block-start: 0;
    margin-block-end: 0;
    
}

.text-info-ind-mtoinfra-num{
    font-size: 18px;
    font-weight: 700;
    color:#03178C;
    margin-block-start: 0;
    margin-block-end: 0;
    
}

.text-info-ind-mtoinfra-gray{
    font-size: 16px;
    font-weight: 500;
    color:#b9b9b9;
    margin-block-start: 0;
    margin-block-end: 0;
}

.separator_indmtoinfra{
    height: 1px;
    width:100%;
    border: none;
    background: #969696;
}

.dashing-chart-container-mtoinfra{
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    width: 50%;
    height: 100%;
    max-height: 256px;
}