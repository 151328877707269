
.main-header {
    position: relative; /* Establece la posición relativa para poder posicionar el pseudo-elemento */
    width: 100%;
    height:840px;
    background-color: #daeafc; /* Fallback color */
    background-image: url('./intro.jpg'); /* Agrega la ruta de la imagen aquí */
    background-size: cover; /* La imagen se ajustará para cubrir todo el contenedor */
    background-position: center; /* La imagen estará centrada en el contenedor */
    background-repeat: no-repeat; /* Evita que la imagen se repita */
}

.link-container-header{
    margin-top: 10px;
}


.header-flex-2{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
   
    width: 50%;
    height: 100%;

    

}

.header-flex-1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 15px;
    width: 50%;
    height: 100%;

    padding: 10px;
    padding-left: 40px;
    

}

.h1-header{
    color: #0455BF;
	font-size: 72px;
	font-weight: 700;
    margin-block-start: 0;
    margin-block-end: 0;

}

.h2-header{
    color: #0455BF;
	font-size: 40px;
	font-weight: 500;
    margin-block-start: 0;
    margin-block-end: 0;

}

.h3-header{
    color: #0455BF;
	font-size: 26px;
	font-weight: 400;
    margin-block-start: 0;
    margin-block-end: 0;

}


  .robot{
    height: 100%;
    width: 100%;
  }

.header-container{
    
    padding-top: 110px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
   

}


@media screen and (max-width: 800px){
    .main-header {
        position: relative; /* Establece la posición relativa para poder posicionar el pseudo-elemento */
        width: 100%;
        height:auto;
        
    }
    .robot{
        height: 80%;
        width: 80%;
      }
            
        .header-flex-2{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            
            width: 100%;
            height: 100%;
            
            

        }

        .header-flex-1{
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: flex-start;
            width: 100%;
            height: auto;
           
          
            padding: 10px;

        }
    
    .header-section{
        width: 100%;
        height: auto;
        background: url('./intro.jpg');
        background-color: #e5e5e5;
        background-size: 100% 100%;
    
    }
    .header-overlay{
        width: 100%;
        height: 100%;
        
       
    }
    .logoheader {
        width: 340px;
        height: 340px;
        margin-top: 40px;
        padding: 10px;
    }
    .home__hero-subtitle{
        width: 300px;
        margin-top: 20px;
        color: #0455BF;
        font-size: 22px;
        font-weight: 300;
        line-height: 30px;
        margin: 0 auto;
        margin-bottom: 15px;
        text-align: center;
    }
    .heading {
        margin-bottom: 10px;
        font-size: 40px;
        line-height: 1.1;
        font-weight: 600;
        color: #f7f8fa;
        text-align: center;
      }
    .linkbtn{
        margin-bottom: 20px;
    }
}


.h1-header,
.h2-header,
.h3-header,
.link-container-header {
    opacity: 0;
    transform: translateX(-50px);
    transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.h1-header.visible,
.h2-header.visible,
.h3-header.visible,
.link-container-header.visible {
    opacity: 1;
    transform: translateX(0);
}

.robot {
    opacity: 0;
    transform: translateX(50px);
    transition: opacity 1.0s ease-out, transform 1.0s ease-out;
}

.robot.visible {
    opacity: 1;
    transform: translateX(0);
}