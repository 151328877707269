.div-accordion-container{
display: flex;
flex-direction: column;
width: 100%;
}
.dialog-title{
    margin-bottom: 10px;
    font-size: 22px;
    line-height: 1.1;
    font-weight: 600;
    color:#0468BF;
    margin-block-start: 10px;
    margin-block-end: 0;
    margin-left: 10px;
}

.text-title-accorion{
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 1.1;
    font-weight: 400;
    color:#0468BF;
    margin-block-start: 10px;
    margin-block-end: 0;
    margin-left: 10px;
}