.container-table-obsolescencia{
    width: 97%;
    margin-left: 15px;
}

.text-card-notificacion-obsol{
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    margin-block-start: 5px;
    margin-block-end: 0px;
    color: #a9a9a9;
}