.filter-container-cal {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    padding-bottom: 10px;
    overflow-x: hidden; /* Previene scroll horizontal */
  }
  
  .filter-inputs-group {
    width: 50%;
    display: flex;
    flex-direction: row;
  }
  
  .filter-input-field {
    flex: 30%;
    margin-right: 10px;
  }
  
  .filter-button-wrapper {
    flex: 20%;
    align-content: center;
    text-align: center;
  }
  
  @media screen and (max-width: 768px) {
    .filter-container-cal {
      padding: 0.5rem;
      flex-direction: column;
    }
  
    .filter-inputs-group {
      width: 100%;
      flex-direction: column;
      max-width: 100%; /* Asegura que no exceda el ancho del contenedor padre */
    }
  
    .filter-input-field {
      flex: 1;
      margin-right: 0;
      margin-bottom: 0.5rem;
      width: 100%; /* Asegura que los inputs ocupen el ancho completo */
    }
  
    .filter-button-wrapper {
      flex: 1;
      width: 100%;
      margin-top: 0.5rem;
    }
  }
  
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .filter-inputs-group {
      width: 100%; /* Cambiado de 70% a 100% para evitar desbordamiento */
      padding-right: 1rem; /* Añade un poco de padding para separar del borde */
      
    }
    
    .filter-input-field {
      flex: 1; /* Distribuye el espacio equitativamente */
    }
  }