
.divhome{
    width: 100%;
}
/* Color Theme Swatches in Hex */
.СВОЙ-КРУГ-от-SBI-Bank-1-hex { color: #044BD9; }
.СВОЙ-КРУГ-от-SBI-Bank-2-hex { color: #2B65D9; }
.СВОЙ-КРУГ-от-SBI-Bank-3-hex { color: #2B76D9; }
.СВОЙ-КРУГ-от-SBI-Bank-4-hex { color: #F2AC29; }
.СВОЙ-КРУГ-от-SBI-Bank-5-hex { color: #F2F2F2; }

/* Color Theme Swatches in Hex */
.BCS-Bank-1-hex { color: #03178C; }
.BCS-Bank-2-hex { color: #0455BF; }
.BCS-Bank-3-hex { color: #041326; }
.BCS-Bank-4-hex { color: #CEDEF2; }
.BCS-Bank-5-hex { color: #0468BF; }

