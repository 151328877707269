.div-card-cursoia{
    border-radius: 5px;
    background: #ffffff;
    box-shadow:  5px 5px 5px #dedede,
                -5px -5px 5px #ffffff;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    height: 100px;
}

.textocursoia{
    font-size: large;
    font-weight: 600;
    color: #0585f4;
    margin-block-start: 0.5em;
    margin-block-end: 0em;
}
.textodescripcioncursoia{
    font-size: medium;
    font-weight: 500;
    color: #a1a1a1;
    margin-block-start: 0.5em;
    margin-block-end: 0em;
}
.div-cursoia-imagen{
    margin-right: 5px;
    background: #0585f4;
}
.imagen-cursoia{
    width: 120px;
    height: 100%;
    object-fit: fill;
    opacity: 50%;
}
.div-card-textocursoia{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}