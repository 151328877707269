.divdoblecotizacion{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-content: flex-start;

}

.divdobleunit{
    width: 100%;
    margin: 2px;
}