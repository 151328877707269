.titulo-tarea-dialog{
    font-size: 24px;
    font-weight: 600;
    color:#03178C;
    margin-block-start: 0px;
    margin-block-end: 5px;
}
.detalles-tarea-dialog{
    font-size: 16px;
    font-weight: 400;
    color:#737373;
    margin-block-start: 0px;
    margin-block-end: 5px;
}

.tarea-info-column{
    display: flex;
    flex-direction: column;
}
.detalles-tarea-titulo-dialog{
    font-size: 12px;
    font-weight: 400;
    color:#919191;
    margin-block-start: 0px;
    margin-block-end: 5px;
}

.div-tarea-prioridad{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.div-card-link-tareas{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px;
    width: 120px;
    height: 30px;
    padding: 10px;
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    text-decoration: none;
    margin-bottom: 10px;
    cursor: pointer;
    flex-grow: 1;
}
.div-card-link-tareas.selected{
    box-shadow: inset 5px 7px 10px #d9d9d9,
            inset -5px -7px 10px #ffffff;
    transition: 0.3s;
    border-radius: 5px;
    width: 120px;
    height: 30px;
    padding: 10px;
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    text-decoration: none;
    margin-bottom: 10px;
    cursor: pointer;
    flex-grow: 1;
    
}

.container-botones-tareas{
    width: 97%;
    display: flex;
    flex-direction: row;
   
}
.dashing-cardcontent-tareas{
    display: flex;
    width: 100%;
   
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}