#crearperfil{
    height: 100%;
    width: 100%;
   position: fixed;
     background: linear-gradient(to right, #e2e1e1 0%,#ffffff  100%);
     color: rgba(4, 85, 191, 1)
 }

 
.crearperfil-container{
    padding: 10px 0;
      display: flex;
      align-content: center;
      justify-content: center;
      
  }

  .crearperfil-card{
    height: 100%;
    width: 430px;
  
    border-radius: 8px;
    background: linear-gradient(to left, #ffffff, #e6e6e6);
    box-shadow:  -12px 12px 24px #c7c7c7, 
                 12px -12px 24px #ffffff;
  }
 
.crearperfil-divtitulo{
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
}
.crearperfil-form{
        display: flex;
        flex-direction: column;
        padding: 10px 10px;
        margin-top: 0px;
    
}
.crearperfil-logo{
    width: 90%;
    margin-top: 20px;
}

.crearperfil-titulo{
    margin-bottom: 10px;
    font-size: 32px;
    line-height: 1.1;
    font-weight: 600;
    color: #03178C;
    margin-block-start: 0;
}