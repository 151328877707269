.text-link-inventariopdf{
    margin-bottom: 1px;
    font-size: 18px;
    line-height: 1.1;
    font-weight:500;
    color:#03178C;
    margin-block-start: 1px;
    margin-block-end: 0;
    text-decoration: none;
}
.inventory-links-grid {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0 auto;
    gap: 1rem;
    flex-wrap: nowrap; /* Cambiado a nowrap para forzar una sola fila */
    align-items: stretch;
    justify-content: space-between; /* Distribuye el espacio uniformemente */
    padding: 1rem 2rem; /* Añadido padding horizontal */
}

/* Para pantallas grandes, aseguramos que cada item tome el mismo espacio */
.inventory-links-grid .div-card-link {
    flex: 1;
    min-width: 0; /* Permite que los elementos se compriman si es necesario */
}

/* Tablet breakpoint */
@media screen and (max-width: 834px) {
    .inventory-links-grid {
        flex-wrap: wrap;
        justify-content: center;
        gap: 0.75rem;
    }
    
    .inventory-links-grid .div-card-link {
        flex: 0 1 calc(50% - 1rem); /* Dos elementos por fila */
    }
}

/* Mobile breakpoint */
@media screen and (max-width: 833px) {
    .inventory-links-grid {
        flex-direction: column;
        width: 100%;
        align-items: center;
        gap: 0.5rem;
        padding: 1rem;
    }
    
    .inventory-links-grid .div-card-link {
        flex: 0 1 100%;
        width: 100%;
    }
}
