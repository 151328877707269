.detallescotizacioncard{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 5px;
    width: 95%;
    height: auto;
    margin-left: 20px;
    margin-top: 10px;
}

.div-informacion-market{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin:10px;
}
.div-equipo-info-market{
    width: 100%;
}
.div-infocontainer-market{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    width: 100%;
}

.terminos-container-market{
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-bottom: 10px;
}

.item-market-card{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    display: flex;
    flex-direction: row;
    margin-left: 15px;
    margin-right: 10px;
    margin-bottom: 15px;
    width: 95%;
    height: auto;
    min-height: 80px;
    align-items: center;
}

.item-market-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 50px;
    margin: 10px;
   
}

.item-market-item{
    display: flex;
    margin: 4px;
    width: 120px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  
}
.item-market-descripcion{
    display: flex;
    margin: 4px;
    width: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.item-market-text{
    margin-left: 0 px;
    font-size: 16px;
    line-height: 1.1;
    font-weight: 400;
    color:#0468BF;
    margin-block-start: 10px;
    margin-block-end: 0;

}
.titulo-market-text{
   
        margin-bottom: 5px;
        font-size: 16px;
        line-height: 1.1;
        font-weight: 500;
        color:#03178C;
        margin-block-start: 10px;
        margin-block-end: 0;
  
}

.div-publicar-market{
    width: 96%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}
    

