#sidebar{
    height: 100%;
    width: 250px;
    position: fixed;
    background: linear-gradient(#ffffff, #cbd0d6);
    border-right-style: groove;
    border-width: 2px;
}
.profile-image{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-top: 15px;
    vertical-align: middle;
    object-fit: scale-down;
    border-color:#cbd0d6;
    border-style: solid;
    border-width: 2px;
}
.sidebar-profile{
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
}
.sidebar-separador{
    height: 3px;
    width: 200px;
    border: none;
    background: linear-gradient(to right, #0468BF 0%, #0589fc 100%);
}
.sidebar-nombre{
    margin-bottom: 10px;
    align-items: center;
    justify-content: center;
    align-content: center;
    text-align: center;
    font-size: 25px;
    line-height: 1.1;
    font-weight: 600;
    color:#03178C;
    margin-block-start: 10px;
    margin-block-end: 0;
}
.sidebar-handle{
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 1.1;
    font-weight: 400;
    color:#0468BF;
    margin-block-start: 10px;
    margin-block-end: 0;
}
.sidebar-icon{
    height: 20px;
    width: 20px;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
}
.sidebar-item{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
    margin-left: 40px;
    margin-top: 15px;
    margin-bottom: 15px;
}
.sidebar-linkdiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
   
}

.sidebar-link{
    text-decoration: none;
    color:#0468BF;
}
.sidebar-link:visited{
    text-decoration: none;
    color:#0468BF;
}
.sidebar-text{
    text-decoration: none;
    font-size: 18px;
    line-height: 1.1;
    font-weight: 400;
    margin-left: 10px;
    margin-block-start: 0;
    margin-block-end: 0;
}
.sidebar-separadoritems{
    border-color:none;
}


@media screen and (max-width: 960px){
    #sidebar{
        display: none;
    }
}
