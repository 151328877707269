.clientes_container{
    min-width: 100%;
    width: 100%;
}

.titulo_section{
    min-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
}
.titulo_clientes{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
  
    margin:10px;
}
.div_boton{
    margin:10px;
}

.text-clientes{
    margin-bottom: 10px;
    font-size: 30px;
    line-height: 1.1;
    font-weight: 600;
    color:#03178c;
    margin-block-start: 10px;
    margin-block-end: 0;
}
.clientes_separator{
    height: 3px;
    width:100%;
    border: none;
    background: linear-gradient(to right, #0468BF 0%, #0589fc 100%);
}