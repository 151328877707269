.div-card-link{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px;
    width: 93%;
    height: auto;
    padding: 10px;
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    text-decoration: none;
    margin-bottom: 15px;
    cursor: pointer;
    flex-grow: 1;
}
.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
  }