/* Equipment Profile Container */
.equipment-profile-container {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 1rem;
  }
  
  /* Equipment Card */
  .equipment-card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 5px;
    width: 95%;
    height: auto;
    margin: 1rem auto;
    padding: 1.5rem;
    background: white;
  }

  .equipment-img-container{
    
   align-items: center;
   align-content: center;
    justify-items: center;
  }
  
  /* Equipment Content Layout */
  .equipment-content {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
  }
  
  .equipment-image-container {
    flex: 0 0 250px;
    max-width: 100%;
  }
  
  .equipment-image {
    width: 100%;
    height: auto;
    border-radius: 4px;
    object-fit: cover;
  }
  
  .equipment-info-wrapper {
    flex: 1;
    min-width: 280px;
  }
  
  /* Equipment Header */
  .equipment-header {
    margin-bottom: 1.5rem;
  }
  
  .equipment-title {
    font-size: 24px;
    color: #03178C;
    margin: 0 0 0.5rem 0;
    font-weight: 600;
  }
  
  .equipment-status,
  .equipment-id {
    font-size: 18px;
    color: #0468BF;
    margin: 0.5rem 0;
    font-weight: 400;
  }
  
  /* Equipment Details */
  .equipment-details-container {
    width: 100%;
  }
  
  .equipment-details-section {
    display: flex;
    width: 90%;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    align-content: space-between;
  }
  
  .detail-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
    min-width: 280px;
  }
  
  .detail-label {
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 1.1;
    font-weight: 500;
    color: #03178C;
    margin-block-start: 10px;
    margin-block-end: 0;
  }
  
  .detail-value {
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 1.1;
    font-weight: 400;
    color: #0468BF;
    margin-block-start: 10px;
    margin-block-end: 0;
    margin-left: 10px;
  }
  
  /* Separators */
  .details-separator {
    width: 100%;
    border: none;
    border-top: 1px solid #e0e0e0;
    margin: 1rem 0;
  }
  
  /* Equipment Actions */
  .equipment-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 1.5rem;
  }
  
  .action-icon {
    font-size: 1.5rem;
  }
  
  /* Action Buttons Container */
  .action-buttons-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    margin: 1.5rem auto;
    width: 95%;
  }
  
  .action-button {
    min-width: 180px !important;
    margin: 0.5rem !important;
    text-transform: none !important;
  }
  
  .action-button.active {
    background-color: #0588FB !important;
    color: white !important;
  }
  
  /* Components Container */
  .components-container {
    border-radius: 5px;
    width: 95%;
    height: auto;
    margin: 1rem auto;
  }
  
  /* Loading State */
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
  }
  
  /* Extended Info Section */
  .equipment-extended-info {
    width: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
  
  /* Page Header */
  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    padding: 0 1.5rem;
  }
  
  .header-content {
    flex: 1;
  }
  
  .page-title {
    color: #03178C;
    font-size: 28px;
    margin: 0;
  }
  
  .header-separator {
    margin: 1rem 0;
    border-top: 2px solid #03178C;
  }
  
  /* Responsive Breakpoints */
  @media (max-width: 1024px) {
    .equipment-card {
      width: 98%;
      margin: 1rem auto;
    }
    
    .detail-row {
      min-width: 240px;
    }
  }
  
  @media (max-width: 768px) {
    .equipment-content {
      flex-direction: column;
    }
    
    .equipment-image-container {
      margin: 0 auto;
    }
    
    .equipment-details-section,
    .equipment-extended-info {
      width: 100%;
    }
    
    .action-buttons-container {
      flex-direction: column;
      align-items: stretch;
    }
    
    .action-button {
      width: 100% !important;
    }
    
    .detail-label,
    .detail-value {
      font-size: 16px;
    }
  }
  
  @media (max-width: 480px) {
    .equipment-card {
      width: 100%;
      padding: 1rem;
    }
    
    .page-header {
      flex-direction: column;
      text-align: center;
    }
    
    .back-button-container {
      margin-top: 1rem;
    }
    
    .equipment-title {
      font-size: 20px;
    }
    
    .equipment-status,
    .equipment-id {
      font-size: 16px;
    }
  }