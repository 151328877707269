.inventario-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;
    gap: 20px;
  }
  
  .inventario-header {
    background-color: white;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .inventario-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .inventario-left-section,
  .inventario-right-section {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  
  .inventario-back-icon {
    color: #03178C;
    font-size: 24px;
  }
  
  .inventario-view-btn {
    text-transform: none !important;
  }
  
  .inventario-content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .inventario-pdf-viewer {
    width: 100%;
    height: 90vh;
    border: none;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .inventario-error-message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .inventario-error-message p {
    color: #666;
    text-align: center;
    font-size: 16px;
    max-width: 400px;
    margin: 0;
  }