/* CSS Reset */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Navbar Styles */
.navbar {
  background: linear-gradient(#ffffff, #cbd0d6);
  height: 80px;
  width: 100%; /* Asegurar 100% de ancho */
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
  padding: 0; /* Quitar padding */
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; /* Asegurar 100% de ancho */
  padding: 0; /* Quitar padding */
  margin: 0; /* Quitar margin */
}

.navbar-logo {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.logonavbar {
  width: 200px;
  height: 100px;
  margin-right: 0.5rem;
}

.menu-icon {
  display: none;
}

.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-item {
  height: 80px;
}

.nav-item:hover {
  border-bottom: 2px solid #03178C;
}

.nav-links {
  color: #03178C;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Media Queries */
@media screen and (max-width: 960px) {
  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 20px;
    font-size: 1.8rem;
    cursor: pointer;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%; /* Asegurar 100% de ancho */
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    background: linear-gradient(#ffffff, #cbd0d6);
    padding: 0; /* Quitar padding */
  }

  .nav-menu.active {
    left: 0;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
  }

  .nav-item {
    width: 100%; /* Asegurar 100% de ancho */
  }
}

@media screen and (max-width: 420px) {
  .navbar {
    padding: 0; /* Quitar padding */
  }

  .navbar-container {
    padding: 0 10px; /* Ajustar padding si es necesario */
  }

  .logonavbar {
    width: 160px;
    height: 80px;
  }
}
