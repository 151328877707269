.indicadoresprestadorcontainer-indp {
    display: flex;
    flex-direction: column;
    width: 100%;
}


.indicadoresrowcontainer-indp {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.indicadoressubcontainer-indp{
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 5px;
}

.link-card-indp{
    text-decoration: none;
}


#dashboard-ingeniero-indp{
    padding: 0px 25px;

}
.dashing-card-indp{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px;
    height: 90px;
    width: 100%;
    margin-right: 20px;
    margin-top: 20px;
    align-content: center;
    align-items: center;
}
.dashing-div-card-indp {
    flex: 1 1 calc(33.33% - 20px);
    margin-right: 10px;
    margin-bottom: 20px;
    min-width: 250px;
}

.dashign-div-secondcard-indp{
    flex:40%;
    margin-right: 10px;
}
.dashing-container-indp{
    display: flex;
    flex-direction: column;
  
   
}

.dashing-rowindicators-indp{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}

.dashing-icon-indp{
    height: 30px;
    width: 30px;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
}
.dashing-cardicon-indp{
    background: linear-gradient(to right, #0455BF 0%, #1276f8 100%);
	box-shadow: 10px 10px 10px rgba(0,0,0,.05);
    padding:5px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.dashing-cardcontent-indp{
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.dashing-cardunder-indp{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
    margin-top: 20px;
    
}

.dashing-textunder-indp{
    margin-block-start: 0;
    margin-block-end: 0;
    color: green;
    font-size: 13px;
    font-weight: 600;
}
.dashing-value-card-indp{
    font-size: 26px;
    font-weight: 600;
    color:#03178C;
    margin-block-start: 0;
    margin-block-end: 0;
}
.dashing-span-indp{
    margin-left: 5px;
    font-size: 13px;
    font-weight: 300;
}


.dashing-div-secondindicators-indp{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}


.dashing-chart-icon-indp{
    height: 25px;
    width: 25px;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;

}
.dashing-card-data-indp{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-right: 10px;
}

.dashing-text-data-indp{
    font-size: 11px;
    font-weight: 500;
    color:#03178C;
    margin-block-start: 0;
    margin-block-end: 0;

}
.dashing-value-data-green-indp{
    font-size: 20px;
    font-weight: 700;
    color:#18941C;
    margin-block-start: 0;
    margin-block-end: 0;
}



.dashing-value-data-red-indp{
    font-size: 20px;
    font-weight: 700;
    color:#E0231C;
    margin-block-start: 0;
    margin-block-end: 0;
}

.dashing-value-data-yellow-indp{
    font-size: 20px;
    font-weight: 700;
    color:#F2AC29;
    margin-block-start: 0;
    margin-block-end: 0;
}
.dashing-value-data-blue-indp{
    font-size: 20px;
    font-weight: 700;
    color:#0455BF;
    margin-block-start: 0;
    margin-block-end: 0;
}



.dashing-cardicon-orange-indp{
    background: linear-gradient(to right, #db681a 0%, #F2711D 100%);
	box-shadow: 10px 10px 10px rgba(0,0,0,.05);
    padding:5px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}



.dashing-cardicon-green-indp{
    background: linear-gradient(to right, #1ea70b 0%, #21BF0D 100%);
	box-shadow: 10px 10px 10px rgba(0,0,0,.05);
    padding:5px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.dashing-cardicon-blue-indp{
    background: linear-gradient(to right,#0697D6 0%,#06C4CC 100%);
	box-shadow: 10px 10px 10px rgba(0,0,0,.05);
    padding:5px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.dashing-cardicon-red-indp{
    background: linear-gradient(to right,#ff0101 0%,#bd0505 100%);
	box-shadow: 10px 10px 10px rgba(0,0,0,.05);
    padding:5px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.dashing-recomendations-indp{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.dashing-divcard-recomendations-indp{
    display: flex;
    flex-direction: column;
    flex:45%;
    margin-right: 10px;
    
}
.dashing-tittlecard-recomendations-indp{
    margin-top: 0 px;
    padding: 10px 10px;
    display: flex;
    flex-direction: column;
}
.dashing-recomendations-card-indp{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px;
    height: 450px;
    width: 100%;
    margin-right: 10px;
    margin-top: 20px;
}

.equipo-image-indp{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-top: 0px;
    vertical-align: middle;
    object-fit: cover;
    border-color:#cbd0d6;
    border-style: solid;
    border-width: 2px;
}
.dashing-equipo-container-indp{
    padding: 5px 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dashing-equipo-card-indp{
    
    border-bottom-style: solid;
    border-top-style: solid;
    border-color: #e2e2e2;
    border-width: 0.5px;
   
}

.dashing-equipo-h4-indp{
    font-size: 18px;
    font-weight: 700;
    color:#0455BF;
    margin-block-start: 0;
    margin-block-end: 0;
}

.dashing-equipo-p-indp{
    font-size: 14px;
    font-weight: 300;
    color:#0455BF;
    margin-block-start: 0;
    margin-block-end: 0;
}
.dashing-equipo-text-indp{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;
}
.dashing-vermascontainer-indp{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 5 px;
    padding: 10px 10px;

}

.dashing-btn-link-indp{
    align-items: flex-end;
}

.dashing-secondindicators-indp {
    display: flex;
    flex-direction: column;
  
    width: 90%;
    flex-wrap: wrap;
}

/* Card de estado */
.dashing-cardestado-indp {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px;
    height: 270px;
    flex: 1 1 20%;
    width: 90%;
    margin-right: 10px;
    margin-top: 20px;
}

/* Container del card */
.dashing-cardcontainer-indp {
    padding: 10px;
    align-items: center;
    align-content: center;
}

/* Texto del card */
.dashing-text-card-indp {
    font-size: 18px;
    font-weight: 500;
    color: #03178C;
    margin-block-start: 0;
    margin-block-end: 0;
}

/* Container del chart */
.dashing-chart-container-indp {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: 100%;
    max-height: 350px;
}

/* Container de datos del card */
.dashing-card-data-container-indp {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

@media (min-width: 1200px) {
    .dashing-secondindicators-indp {
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: nowrap;
        width: 100%;
    }
    .dashing-cardestado-indp {
        width: 23%;
        margin-right: 10px;
        margin-top: 15px;
    }
}

@media (max-width: 1200px) {
    .dashing-div-card-indp {
        flex: 1 1 calc(50% - 20px);
    }
    .dashing-card-indp {
        height: auto;
    }
   
    .dashign-div-secondcard-indp {
        flex: 1 1 100%;
        margin-right: 0;
    }
    .dashing-secondindicators-indp {
        flex-direction: column;
        width: 100%;
    }
    .dashing-cardestado-indp {
        flex: 1 1 100%;
        width: 100%;
        margin-right: 0;
    }
}

@media (max-width: 768px) {
    .indicadoresrowcontainer-indp {
        flex-direction: column;
    }
   
    .dashing-value-card-indp,
    .dashing-text-card-indp {
        font-size: 20px;
    }
    .dashing-value-data-green-indp,
    .dashing-value-data-red-indp,
    .dashing-value-data-yellow-indp,
    .dashing-value-data-blue-indp {
        font-size: 16px;
    }
    .dashing-text-data-indp {
        font-size: 10px;
    }

    .dashing-div-card-indp {
        flex: 1 1 100%;
        margin-right: 0;
    }

    .dashing-cardicon-indp {
        width: 35px;
        height: 35px;
    }

    .dashing-icon-indp {
        height: 20px;
        width: 20px;
    }

}

@media (max-width: 576px) {
    .dashing-cardcontainer-indp {
        padding: 10px;
    }

    .dashing-cardicon-indp {
        width: 30px;
        height: 30px;
    }

    .dashing-icon-indp {
        height: 18px;
        width: 18px;
    }

    .dashing-text-card-indp {
        font-size: 12px;
    }

    .dashing-value-card-indp {
        font-size: 18px;
    }
   
    .dashing-card-data-container-indp {
        flex-direction: column;
    }
    .dashing-card-indp {
        height: auto;
        margin-right: 0;
    }
}