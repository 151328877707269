.icon-crearcliente{
    width: 30px;
    height: 30px;
    color:#03178C;
    margin:5px;
}
.icon-formulario{
    width: 20px;
    height: 20px;
}
.formcontrol-nuevocliente{
    width: 100%;
}