
/* Estilos generales */
#caracteristicas {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
    background: linear-gradient(to right, #02126f 0%, #004094 100%);
}

.container1, .container2 {
    flex: 1 1 100%;
    padding: 10px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.monitor {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 1.0s ease-out, transform 1.0s ease-out;
}

.monitor.visible {
    opacity: 1;
    transform: translateY(0);
}

.container2 {
    opacity: 0;
    transform: translateX(50px);
    transition: opacity 1.0s ease-out, transform 1.0s ease-out;
}

.container2.visible {
    opacity: 1;
    transform: translateX(0);
}

/* Estilos para pantallas grandes */
@media (min-width: 768px) {
    .container1, .container2 {
        flex: 1 1 50%;
    }
}

.h1-ch{
    color: #FFFFFF;
    font-size: 72px;
    font-weight: 700;
    margin-block-start: 0;
    margin-block-end: 0;
}

.h2-ch{
    color: #FFFFFF;
    font-size: 40px;
    font-weight: 500;
    margin-block-start: 0;
    margin-block-end: 0;
}

.h3-ch{
    color: #FFFFFF;
    font-size: 26px;
    font-weight: 400;
    margin-block-start: 0;
    margin-block-end: 0;
}
  /* Estilos para pantallas grandes */
  @media (min-width: 768px) {
    .container1, .container2 {
      flex: 1 1 50%;
    }
  }
  .h1-ch{
    color: #FFFFFF;
	font-size: 72px;
	font-weight: 700;
    margin-block-start: 0;
    margin-block-end: 0;

}

.h2-ch{
    color: #FFFFFF;
	font-size: 40px;
	font-weight: 500;
    margin-block-start: 0;
    margin-block-end: 0;

}

.h3-ch{
    color: #FFFFFF;
	font-size: 26px;
	font-weight: 400;
    margin-block-start: 0;
    margin-block-end: 0;

}
.text-center {
    text-align: center;
}
.separadorgradiente{
    height: 5px;
    width: 100px;
    border: none;
    background: linear-gradient(to right, #0468BF 0%, #0589fc 100%);
}
.caract-container{
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.caract-titulo-h1{
    margin-bottom: 10px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: #f7f8fa;
}
.caract-titulo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 50px;
}
.caracter{
    width: 180px;
    margin-left: 40px;
    margin-right: 40px;
}
.caract-icono{
    font-size: 38px;
    margin-bottom: 0px;
	transition: all 0.5s;
	color: #fff;
    width: 150px;
	height: 100px;
	padding: 30px 0;
	border-radius: 50%;
	background: linear-gradient(to right, #0468BF 0%, #0589fc 100%);
	box-shadow: 10px 10px 10px rgba(0,0,0,.05);
}
.row{
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
}
.titulo-caracteristica{
    color: #f7f8fa;
    margin-bottom: 10px;
    font-size: 30px;

}
.caract-descripcion{
    color: #fff;
	font-size: 18px;
	font-weight: 300;
	line-height: 25px;
	margin: 0 auto;
	
}
@media screen and (max-width:912px){
    .row {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;
    }
    .caract-descripcion {
        color: #fff;
        font-size: 18px;
        font-weight: 300;
        line-height: 25px;
        margin: 0 auto;
        margin-bottom: 30px;
    }
}
@media screen and (max-width: 412px){
    .row{
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;
    }
    .titulo-caracteristica{
        margin-block-start: 0;
        color: #f7f8fa;
        margin-bottom: 10px;
        font-size: 30px;
        
    
    }
    .caract-descripcion {
        color: #fff;
        font-size: 18px;
        font-weight: 300;
        line-height: 25px;
        margin: 0 auto;
        margin-bottom: 30px;
    }
}